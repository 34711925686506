<template>
    <div v-frag>
        <div class="container-fluid">
        <div class="row" style="padding-bottom:60px">
          <div class="col-md-2 hidden-sm hidden-xs"></div>
          <div class="col-md-8">
            <p class="visible-xs"></p>
            <div class="main-carousel">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="item active">
                            <img :src="`${publicPath}images/aviatrix.png`" alt="en-rahisibet">
                        </div>          
                    </div>
                </div>
            </div>
            <div class="" style="margin: 14px 0px;">
              <input type="text" v-model="search"  class="form-control" :placeholder="`${$t('betslip.search')}`">
            </div>
            <div class="aviatrix-game" :class="bg">
                <div class="aviatrix-button" :class="{'selected' : show}">
                    <button class="btn btn-sm bg-orange" style="margin-right: 15px;" @click="playDemo()">{{ $t('casino.play-demo') }}</button>
                    <button class="btn btn-sm bg-orange" @click="playLive()">{{ $t('casino.play-game') }}</button>
                </div>
            </div>
          </div>
          <div class="col-md-2 hidden-sm hidden-xs"></div>
        </div>
      </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                publicPath: process.env.BASE_URL,
                locale: this.$i18n.locale,
                search:'',
                show: false,
            }
        },
        computed: {
            ...mapGetters({
                geo:'geo',
                user:'user',
            }),
            iso() {
                return (this.user) ? this.user.iso : this.geo?.country_code2;
            },
            bg() {
                if (this.iso == 'KE') {
                    return 'dke'
                } else if (this.iso == 'BI') {
                    return 'dbi';
                } if (this.iso != 'KE' && this.iso != 'BI' && this.$i18n.locale == 'en') {
                    return 'den';
                } else {
                    return 'dfr'
                }
            }
        },
        methods: {
            showDiv: function() {
                this.show = !this.show;
            },
            playDemo() {
                this.$router.push({ name: 'demo_aviatrix_renderer'});
            },
            playLive() {
                this.$router.push({ name: 'live_aviatrix_renderer' });
            }
        }
    }
</script>
<style scoped>
img {
    cursor: pointer;
    width: 100% !important;
    max-height: 200px;
}
.form-control {
    background-color: var(--bg-dark-medium);
    color: var(--a-text);
    border: 1px solid var(--border-color);
}
.casino {
    margin-top: 10px;
    margin-bottom: 10px;
}
.casino-card {
    border: 1px solid #494d59;
    border-radius: 10px;
    position: relative;
    /* background-color: #494d59 ; */
}
.aviatrix-game {
    position: relative;
    
}
.aviatrix-button {
    position: absolute;
}
.btn-sm, .btn-group-sm > .btn {
	padding: 5px 10px;
	font-size: 12px;
	
	border-radius: 5px;
    font-weight: 500;
}


@media screen and (min-width: 768px) {
    .dke{
        background-image: url('./images/dKes.jpg');
    }
    .dbi{
        background-image: url('./images/dBif.jpg');
    }
    .den{
        background-image: url('./images/dEn.jpg');
    }
    .dfr{
        background-image: url('./images/dFr.jpg');
    }
    .aviatrix-game {
        height: 600px;
        
        /* background-image: url('dBif.jpg'); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .aviatrix-button {
        bottom: 70px;
        right: 50px;
    }
    .btn-sm, .btn-group-sm > .btn { 
        line-height: 1.8;
    }
}

@media screen and (max-width: 767px) {
    .dke{
        background-image: url('./images/mKes.jpg');
    }
    .dbi{
        background-image: url('./images/fBg.jpg');
    }
    .den{
        background-image: url('./images/mEn.jpg');
    }
    .dfr{
        background-image: url('./images/mFr.jpg');
    }
    .aviatrix-game {
        height: 400px;
        /* max-height: 200px; */
        /* background-image: url('mKes.jpg'); */
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
    .aviatrix-button {
        bottom:5%;
        left: 10px;
    }
    .btn-sm, .btn-group-sm > .btn { 
        line-height: 1.3;
    }
}
</style>